// (standard, VLC, publishing, Widgets)
function getSelectionTextAndContainerElement() {
    var text = "", containerElement = null;

    if (typeof window.getSelection != "undefined") {
        var sel = window.getSelection();
        if (sel.rangeCount) {
            var node = sel.getRangeAt(0).commonAncestorContainer;
            containerElement = node.nodeType == 1 ? node : node.parentNode;
            text = sel.toString();
        }
    } else if (typeof document.selection != "undefined" && document.selection.type != "Control") {
        var textRange = document.selection.createRange();
        containerElement = textRange.parentElement();
        text = textRange.text;
    }

    return {
        text: text,
        containerElement: containerElement
    };
}

var htmlToBbcode = function(str) {

    str = str.replace(/\n/g, "\t");
    str = str.replace(/\r/g, "\t");

    str = str.replace(/<strong>(.*)<\/strong>/gi, "[b]$1[/b]");
    str = str.replace(/<strike>(.*)<\/strike>/gi, "[s]$1[/s]");
    str = str.replace(/<u>(.*)<\/u>/gi, "[u]$1[/u]");
    str = str.replace(/<em>(.*)<\/em>/gi, "[i]$1[/i]");

    str = str.replace(/<sup>(.*)<\/sup>/gi, "[sup]$1[/sup]");
    str = str.replace(/<sub>(.*)<\/sub>/gi, "[sub]$1[/sub]");

    str = str.replace(/<font face=(.*)>(.*)<\/font>/gi, "[font=$1]$2[/font]");
    str = str.replace(/<font size=(.*)>(.*)<\/font>/gi, "[size=$1]$2[/size]");
    str = str.replace(/<font face=(.*) size=(.*)>(.*)<\/font>/gi, "[font=$1][size=$2]$3[/size][/font]");

    str = str.replace(/<p align=left>(.*)<\/p>/gi, "[align=left]$1[/align]");
    str = str.replace(/<p align=right>(.*)<\/p>/gi, "[align=right]$1[/align]");
    str = str.replace(/<p align=center>(.*)<\/p>/gi, "[align=center]$1[/align]");

    str = str.replace(/<blockquote>(.*)<\/blockquote>/gi, "[quote]$1[/quote]");
    str = str.replace(/<blockquote dir=ltr style="MARGIN-RIGHT: 0px">(.*)<\/blockquote>/gi, "[indent]$1[/indent]");

    str = str.replace(/<br>/gi, "\n");

    return str;
};

var showPopupMessage = function(message, $owner) {
    /* Quickie method that gives me a vanishing tooltip to display after ajax-style operations */
    var $popupMessage = $('#popup-message');

    if ($popupMessage.length == 0) {
        $('body').append('<div id="popup-message"></div>');
        $popupMessage = $('#popup-message');
    }
    
    $popupMessage.html(message);
    $popupMessage.css({ left: $owner.offset().left + (($owner.width() - $popupMessage.width()) * 0.5), top: $owner.offset().top + (($owner.height() - $popupMessage.height()) * 0.5), opacity: 1 }).show().delay(500).animate({ opacity: 0 }, 'slow', 'swing', function(){ $(this).hide(); });
};

/* Add quote-post handlers for forums */
$('.quote-post').click(function(e) {
    e.preventDefault();
    var $this = $(this);
    var $parentPost = $(this).closest('.comment');
    var $tgt = $('form#post textarea[name="body"]');

    if (($tgt.length == 0) || ($parentPost == 0)) {
        return false;
    }

    /* Test that the selected text is within the same post as the quote post */
    var selection = getSelectionTextAndContainerElement();

    if ((selection.text.length == 0) || ($(selection.containerElement).closest('.comment')[0] != $parentPost[0])) {
        selection['text'] = $parentPost.find('.body').html();
    }

    var author = $parentPost.find('.author').text();

    if ($tgt.val().length) { $tgt.val($tgt.val() + '\n\n'); } // whitespace
    $tgt.val($tgt.val() + '[quote][i]' + author + ' ' + gTerms.forum_quote_said + ':[/i]\n' + htmlToBbcode(selection['text']) + '[/quote]\n\n');

    // Show popup message to let the user know their click actually did something (in case it's off-screen)
    showPopupMessage(gTerms.forum_quote_quoted, $this);
});
