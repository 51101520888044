import './community';

window.corpu = window.corpu ?? {}

/**
 * { CorpU Modals Object }
 * - contains state for modals globally.
 */
window.corpu.modals = window.corpu.modals ?? {}
window.corpu.modals.blocking = false

// window.addEventListener('corpu:modals:blocking:end', e => {
//   console.log('Ending Target:', e.detail.$el)
// })
// window.addEventListener('corpu:modals:blocking:start', e => {
//   console.log('Starting Target:', e.detail.$el)
// })

class CustomTextArea extends HTMLTextAreaElement{
    constructor() {
        super();  
    }
    connectedCallback() {
        this.maxLength = 300000;
        const percent90CharacterCount = Math.floor((this.maxLength/100)*90); /* we are calculating 90% of the max character count allowed*/
        const divElem = document.createElement('span');
        divElem.classList.add("pull-right");
        divElem.classList.add("character-count");
        divElem.style.cssText = 'color:gray;top:0';

        // Setup a click listener on <custom-text-area> itself.
        this.addEventListener('keyup', e => {
            const customTextAreaMaxLength = parseInt(e.currentTarget.getAttribute('maxLength'));
            let currentCharCount = e.currentTarget.value.length;

            if (currentCharCount > customTextAreaMaxLength) {
                return false;
            } else {
                if (currentCharCount >= percent90CharacterCount) { 
                    divElem.textContent = customTextAreaMaxLength - currentCharCount;
                    this.parentNode.insertBefore(divElem,this.nextSibling);
                    divElem.style.display = 'block';
                } else {
                    divElem.style.display = 'none';
                }
            }
        });
    }
}

customElements.define("custom-text-area", CustomTextArea, {extends: 'textarea'}); 
